.App {
  text-align: center;
  background-color:  rgb(117, 91, 13);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #f89617;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(91, 74, 3);
}

.App-image{
  
    width: 100%;
    /* position:absolute; */
    padding: 0px;
    object-fit: contain;
    position:absolute; z-index:2;
    top: 0px;

}

.App-QR{
    top: 0px;
    /* position:absolute; z-index:1; */

}
.App-div-central{
  position:absolute; z-index:3;
  /* display: flex;
  flex-direction: column; */
  top: 30%;
  /* left: auto; */
  /* align-items: center;
  align-content: center;
  align-items: center;
  justify-content: center; */
  text-align:center;
  color:white;
    /* Center horizontally*/
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}

.App-button{
  border-radius: 25px;
  padding: 17px;
  margin: 0px;
  color : white;
  background-color:#deae29;
  font-size:  17px;
}

.App-input{
  border-radius: 25px;
  padding: 7px;
  margin: 0px;
  color : white;
  background-color:#b28416;
  font-size:  25px;
  text-align: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
